import React                 from 'react';
import { Flex, Box, Center } from '@chakra-ui/react';
import Img                   from 'gatsby-image';
import { graphql }           from 'gatsby';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import { findMedia } from '@interness/web-core/src/components/_helpers';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Separator     from '@interness/web-core/src/components/structure/Separator/Separator';

import Subnavigation from '../components/Subnavigation';

const MehrwegPalettePage = (props) => {
  return (
    <>
      <HeaderMedia id="produkte"/>
      <Subnavigation title="Produkte" menu={[
        {
          'displayName': 'Fertigung',
          'path': '/fertigung'
        },
        {
          'displayName': 'Europaletten',
          'path': '/europaletten'
        },
        {
          'displayName': 'Paletten 1/2/3 weg',
          'path': '/paletten-123-weg'
        },
        {
          'displayName': 'Sonderpaletten',
          'path': '/sonderpaletten'
        },
        {
          'displayName': 'Chemiepaletten',
          'path': '/chemiepaletten'
        },
        {
          'displayName': 'Euro-Gitterboxen',
          'path': '/euro-gitterboxen'
        },
        {
          'displayName': 'Vermietung / Reparaturen',
          'path': '/vermietung-reparaturen'
        }
      ]}/>
      <Spacer/>
      <Wrapper>
        <Heading>Paletten 1/2/3-Weg</Heading>

        <Center textAlign='center'>
          Individuell für jede Anwendung.
        </Center>

        <Spacer/>

        <Flex flexWrap="wrap">
          <Box width={['100%', '100%', '40%', '40%']}>
            <h3>Einweg-Palette</h3>
            <p>Kennzeichen und Qualitätsmerkmal</p>
            <ul>
              <li>Diese Palettenart wird auch als Verlust-Palette bezeichnet, da sie beim Empfänger verbleibt.</li>
              <li>Verlust-Paletten werden grundsätzlich in sparsamster Ausführung hergestellt, entsprechend nach Art, Größe und Gewicht des Produktes.</li>
              <li>Verlust-Paletten können in Vierwege- oder Zweiwege-Ausführung hergestellt werden.</li>
              <li>Nehmen Sie unverbindlich Kontakt mit uns auf und lassen Sie sich ein Angebot erstellen.</li>
            </ul>
          </Box>
          <Box width={['100%', '100%', '60%', '60%']} px={6}>
            <Center>
              <Img fixed={findMedia(props.data.collection.images, 'pg-einweg-palette').childImageSharp.fixed}/>
            </Center>
          </Box>
          <Separator/>
          <Box width={['100%', '100%', '40%', '40%']}>
            <h3>Zweiweg-Palette</h3>
            <p>Ideal für Stein und Beton</p>
            <ul>
              <li>Diese Palettenart ist von zwei Seiten mit dem Gabelstapler einfahrbar.</li>
              <li>Für Steine und Betonwaren ist sie die bevorzugte Palette.</li>
              <li>Die Zweiwege-Palette wird überwiegend als Transport-Palette eingesetzt. Sie ist die preiswerte Alternative zur Vierwege-Palette.</li>
            </ul>
          </Box>
          <Box width={['100%', '100%', '60%', '60%']} px={6}>
            <Center>
              <Img fixed={findMedia(props.data.collection.images, 'pg-zweiweg-palette').childImageSharp.fixed}/>
            </Center>
          </Box>
          <Separator/>
          <Box width={['100%', '100%', '40%', '40%']}>
            <h3>Vierweg-Palette</h3>
            <p>Für alle Ansprüche</p>
            <ul>
              <li>Durch die Einfahrmöglichkeit in alle vier Seiten ist sie die universelle Palette. Sie ist dadurch leicht und schnell zu bewegen und braucht wenig Verkehrsraum.</li>
              <li>Die Vierwege-Palette wird im Gütertransport von Ort zu Ort und besonders im Lagerbereich wie z.B. in Kühlhäusern und Hochregal-Lagersystemen eingesetzt.</li>
              <li>Für das innerbetriebliche Transportwesen in Molkereien, Bau- und Supermärkten u.v.a. ist sie die ideale Palette.</li>
            </ul>
          </Box>
          <Box width={['100%', '100%', '60%', '60%']} px={6}>
            <Center>
              <Img fixed={findMedia(props.data.collection.images, 'pg-vierweg-palette').childImageSharp.fixed}/>
            </Center>
          </Box>
        </Flex>
      </Wrapper>
      <Spacer />
    </>
  )
};

export default MehrwegPalettePage;


export const query = graphql`
    query {
        collection: directusMediaCollection(name: {eq: "paletten-123"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fixed {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    }
`;